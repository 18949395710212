import React, { useContext, useEffect } from "react";
import AppContext from "@src/context";
import Seo from "@components/utility/SEO";
import { KfHeroUtility } from "@klickinc/kf-react-components";

const MakingTheGrade = () => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		ctx.setPageClass("page--transcript page--transcript-making-the-grade-es");
	}, []);

	return (
		<>
			<section className="transcript-section">
				<div className="container container--inner">
					<KfHeroUtility addedClass="heading-hero-blue heading">
						Transcript
					</KfHeroUtility>
					<p className="uppercase mb-4">Este video se basa en la experiencia personal de una familia con un chico que toma emflaza<sup>&reg;</sup> <span className="lowercase">(deflazacort)</span>. Hable con un proveedor de atención médica antes de tomar decisiones sobre el tratamiento.</p>

					<div className="script">
						<h2 className='orange-heading'>Haciendo un cambio: la historia de tratamiento de Christopher <br /> conozcamos a la familia perez y a christopher</h2>
						<div className="script--body">
							<p><span className="name">Sima:</span> <span className="copy">Hola. Yo me llamo Sima Pérez. Vivo en Long Beach, California y yo vivo con mis dos hijos y mi esposo.</span></p>
							<p><span className="name">Sima:</span> <span className="copy">Mi niña tiene dieciocho años, se llama Rebeca. Y Christopher, mi hijo, tiene diecisiete años. Y él tiene Duchenne, distrofia muscular.</span></p>
							<p><span className="name">Sima:</span> <span className="copy">Ahorita va a estar en el doce grado y él se gradúa de la high school y él quiere ir al colegio.</span></p>
						</div>
					</div>

					<div className="script">
						<h2 className='orange-heading'>Nuestra experiencia con los corticosteroides</h2>
						<div className="script--body">
							<p><span className="name">Sima:</span> <span className="copy">Cuando Christopher tenía seis a siete años, empezó él con corticosteroides y no ha parado. La doctora siempre ha pensado que Christopher estaba bien cuando él estaba caminando. Desde los siete años hasta los nueve años él usó corticosteroides y la idea era que necesitábamos que él continuara caminando, que no se nos debilitara tan pronto. Siento yo que la relación con los doctores ha sido y continúa siendo espectacular.</span></p>
							<p><span className="name">Sima:</span> <span className="copy">Al principio mi esposo y yo no estábamos muy bien de acuerdo que nuestro hijo empezara tan temprano, a una edad muy temprana con corticosteroides. Pero después de platicar con la neuróloga, ésta sería la mejor opción para nuestro hijo. Y pues, lo pusimos, él empezó a tomar la medicina.</span></p>
							<p><span className="name">Sima:</span> <span className="copy">Yo cambié mi modo de pensar de corticosteroides después que él dejara de caminar, porque yo pensé “Ya no necesita mi hijo seguir tomando este tipo de medicamento, puesto que él ya no camina, ya no es ambulante”. Pero la doctora tenía otra idea en mente, y ella nos dijo que, en realidad, el hecho de que él dejó de caminar era algo que iba a pasar naturalmente. Lo que vamos hoy a continuar es preservando la función muscular.</span></p>
							<p><span className="name">Sima:</span> <span className="copy">La neuróloga y yo hicimos la decisión de cambiar a Christopher a EMFLAZA.</span></p>
							<p><span className="name">Sima:</span> <span className="copy">La idea es de que mi hijo siga usando EMFLAZA para toda la vida. Va a ser algo que va a ser muy importante para él continuar, puesto que esto le va a beneficiar para preservar la función muscular y ayudarle a que esté fuerte.</span></p>
							<p><span className="name">Sima:</span> <span className="copy">Nosotros tenemos una trayectoria con el programa de PTC <span className="italic">Cares</span>™ y nuestro gerente Rick es una linda persona. Él me habla seguido para recordarme que mi hijo tiene su medicina lista para mandármela a la casa. Es excelente programa, yo estoy muy contenta porque a mi niño me le dan la medicina, me la mandan por correo y la recibimos a tiempo. Y hasta hoy es algo que yo se los sugiero a padres que están pensando en empezar a su hijo en EMFLAZA, que participen en este programa.</span></p>
						</div>
					</div>

					<div className="script">
						<h2 className='orange-heading'>Apoyar un cambio</h2>
						<div className="script--body">
							<p><span className="name">Rick:</span> <span className="copy">Mi nombre es Rick Rodríguez. Soy un administrador de casos aquí con PTC <span className="italic">Cares</span>. Llevo tres años con PTC <span className="italic">Cares</span> y durante ese tiempo he desarrollado muchas relaciones bonitas con nuestros pacientes y estamos aquí para servirle a la comunidad hispana.</span></p>
							<p><span className="name">Rick:</span> <span className="copy">La primera reunión con Sima fue debido a un problema con la farmacia y el seguro. Para ese tiempo, Chris tenía como una semana de medicamentos. Esa fue la primera vez que hablamos. Y gracias a Dios, con la ayuda de Sima, ese problema se arregló en menos de una semana. Pero esa fue la primera vez que hablamos.</span></p>
							<p><span className="name">Rick:</span> <span className="copy">Tenemos muchos servicios que ofrecemos a nuestras familias. Algunos de ellos son que ayudamos con la verificación del seguro. Nosotros entendemos que ese proceso puede llevar mucho tiempo y a veces es frustrante. Ayudamos con eso. También ayudamos a revisar los gastos, si hay gastos con mi medicamento. Y también trabajamos con la farmacia especializada que va a dispensar el medicamento. Trabajamos muy cerca a ellos y en todo lo que podemos hacer para apoyar a nuestras familias.</span></p>
							<p><span className="name">Rick:</span> <span className="copy">Darnos una llamada, estamos aquí para soportarlo, para contestar todas las preguntas que tenga y para que sepa que la persona en la otra línea le va a apoyar con todo.</span></p>
						</div>
					</div>

					<div className="script">
						<h2 className='orange-heading'>La comunidad de duchenne</h2>
						<div className="script--body">
							<p><span className="name">Sima:</span> <span className="copy">Como promotora comunitaria de la comunidad, es un placer para mí de verdad hablar con mamás y papás que están pasando por una situación similar a la mía. Yo me pongo a la disposición de los padres que tienen preguntas. Mi niño, como les dije, va a cumplir diecisiete años y muchos de los papás son nuevos. Sus hijos están diagnosticados recientemente y ellos tienen preguntas acerca de qué hacer en la escuela, cómo navegar la escuela, cómo hablar con el doctor acerca de corticosteroides, y para eso estoy yo. Para ayudarles y brindarles un poco de información y darles un poco de mi trayectoria como madre, con un niño con distrofia muscular.</span></p>
							<p><span className="name">Sima:</span> <span className="copy">Christopher está creciendo, está madurando. Y lo que sé es que EMFLAZA va a continuar con la misma jornada y siguiendo los avances de mi hijo para que él siga fuerte y siga viendo un futuro brilloso. Así que EMFLAZA está con nosotros y está con Christopher para el resto de su vida.</span></p>

							<h2 className='orange-heading'>Consulte a su médico si un cambio le puede ayudar</h2>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default MakingTheGrade;

export function Head() {
	return (
		<Seo title="Transcripción del vídeo: haciendo un cambio - EMFLAZA® (deflazacort)" addSocialMeta={false}>
			<meta name="robots" content="noindex" />
		</Seo>
	);
};
